import * as React from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { MainPicture } from '../components/MainPicture';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '../components/layout/StyledComponents';
import styled from '@emotion/styled';
import { Seo } from '../components/Seo';
import { colors, device } from '../components/layout/GlobalStyles';

const StyledFirstVisit = styled.div`
    padding: 2em 1em;
    .segment {
        margin-top: 3em;
    }
    p {
        font-size: 1em;
        line-height: 30px;
        margin-top: 1em;
    }
    .first-paragraph {
        margin-top: 2.5em;
    }
    h4 {
        font-size: 24px;
        color: ${colors.blueDark};
    }
    h5 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
    .button-flex {
        button {
            margin: 3em 0em;
        }
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    @media ${device.tablet} {
        padding-top: 0em;
        .first-paragraph {
            margin-top: 4.5em;
        }
        .text {
            margin: 0 auto;
            max-width: 700px;
        }

        .button-flex {
            button {
                margin: 2em 0em;
            }
        }
    }
    @media ${device.laptopL} {
        button {
            margin: 2em 0em;
        }
        .text {
            margin: 0 auto;
            max-width: 846px;
        }
        padding: 3em 0em;

        h5 {
            margin: 2em 0em 1.4em;
        }
    }
    @media ${device.desktopL} {
        .text {
            margin: 0 auto;
            max-width: 1265px;
        }

        h4 {
            font-size: 24px;
            color: ${colors.blueDark};
        }
        button {
            margin: 2.5em 0em;
        }
        h5 {
            padding: 0em 1.6em;
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
        }
        p {
            padding: 0em 2em;
            line-height: 32px;
            font-size: 1.2em;
        }
    }
    @media ${device.tablet} {
        padding: 2em 0em;
    }
`;

const FirstVisit: Page = () => (
    <DefaultLayout currentPage="Your First Visit">
        <Seo
            title="Your First Visit – Magic Smile Dental"
            description="Magic Smiles wants you to feel comforable and happy with you first visit! Read through our provided information on visits and appointments."
        />
        <StyledFirstVisit>
            <MainPicture title="Your First Visit">
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../images/smiley-balloons.jpeg"
                    alt="group of hands on a wooden table"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../images/smiley-balloons.jpeg"
                    alt="group of hands on a wooden table"
                />
            </MainPicture>
            <div className="text">
                <p className="first-paragraph">
                    If you’re like most patients you appreciate being informed before arriving at
                    the dentist. Below is an overview of what you can expect during your first visit
                    to Magic Smile Dental.
                </p>
                <div className="button-flex">
                    <Button color="orange">Download New Patiend Form PDF</Button>
                </div>
                <h4>Let’s get to know each other</h4>
                <p>
                    Your first visit is not only about our team getting to know you, but also about
                    you getting to know our team. In the time we spend together, we encourage you to
                    ask questions, express your concerns, and share with us anything you want us to
                    know to help make your care as individual as you are.
                </p>
                <p>
                    Part of getting to know you and providing safe, personalized support involves
                    reviewing your medical history. Certain medical conditions and medications and
                    can influence your treatment protocols – so you will be asked to provide a list
                    of your medications and their dosages (including vitamins and supplements).
                </p>
                <p>
                    Next, a full-mouth series of x-rays and a comprehensive oral exam of your teeth,
                    gums, mouth, and jaw will be performed, including a non-invasive oral cancer
                    screening.
                </p>
                <div className="segment">
                    <h4>You’re in great hands</h4>
                    <p>
                        And if you haven’t been to a dentist in a long while, please don’t worry.
                        You’ve made an important decision – no need to feel guilty!
                    </p>
                    <p>
                        Once your entire exam is completed, one of our doctors will talk with you
                        about their findings. Together you can create a healthy, effective treatment
                        plan that best meets your dental and personal requirements. We can
                        prioritize and stagger your treatments to give you the smile you need in a
                        timeframe you can afford.
                    </p>
                    <p>
                        Finally, we want you to know we will go above and beyond to make you feel
                        relaxed and safe. Not only is your physical comfort important to us, but
                        your emotional comfort is as well. So, come in, recline and unwind – you’re
                        in great hands.
                    </p>
                </div>
                <div style={{ paddingBottom: '2em' }} className="button-flex">
                    <Button>
                        <StaticImage
                            className="blue-mobile"
                            src="../images/svgs/mobile-white.svg"
                            placeholder="none"
                            alt="white mobile phone"
                        />
                        Call Us at (908) 486-5000
                    </Button>
                </div>
            </div>
        </StyledFirstVisit>
    </DefaultLayout>
);

export default FirstVisit;
